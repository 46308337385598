import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentTypeRelation } from '@parashift/shared/models';
import { DocumentTypeRelationType } from '@parashift/shared/types';
import { ServiceDecorator } from './decorators';
import { BaseApiService } from './base';
import { QueryParamsService } from '../query-params.service';
import { ENVIRONMENT_CONFIG } from '@parashift/shared/environment-config';

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => DocumentTypeRelation,
  endpointUrl: DocumentTypeRelationType
})
export class DocumentTypeRelationService extends BaseApiService<DocumentTypeRelation> {
  className = DocumentTypeRelationService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService,
    @Inject(ENVIRONMENT_CONFIG) private environment: any
  ) {
    super(http, queryParamsService);
    this.baseUrl = this.environment.endpoints.individual_extraction;
    this.apiVersion = this.environment.endpoints.individual_extraction_version
  }
}

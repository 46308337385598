import { Model, Attribute, BelongsTo } from './decorators';
import { BaseApiModel } from './base';
import { DocumentTypeField } from './document-type-field.model';
import { DocumentTypeFieldset } from './document-type-fieldset.model';
import { ExtractionRule } from './../interfaces/extraction-rule.interface';
import { DocumentTypeRelationType, DocumentTypeFieldType, DocumentTypeFieldsetType } from '@parashift/shared/types';
import { Positioning } from './../interfaces/interfaces.index';
import { sortBy } from '@parashift/shared/utils';
import { groupBy } from '@parashift/shared/utils';

export interface DocumentTypeRelationPlainModel {
  id: string;
  confidence_lower_threshold: number;
  confidence_threshold: [number, number];
  confidence_upper_threshold: number;
  discarded_at: string;
  document_type_field_id: number;
  document_type_fieldset_id: number;
  document_type_id: number;
  document_type_section_id: number;
  extraction_rules: ExtractionRule[];
  identifier: string;
  not_for_training: boolean;
  not_for_validation: boolean;
  optional: boolean;
  position: number;
  positioning: Positioning;
  recognition_confidence_threshold: number;
  recognition_threshold: number;
  tenant_id: number;
  title: string;
  use_standard: boolean;
  validation_help: string;
  version: string;

  document_type_field: DocumentTypeField['plainModel'] | Partial<DocumentTypeField['plainModel']>;
  document_type_fieldset: DocumentTypeFieldset['plainModel'] | Partial<DocumentTypeFieldset['plainModel']>;
  parent: DocumentTypeRelation['plainModel'] | Partial<DocumentTypeRelation['plainModel']>;
}

@Model({ type: DocumentTypeRelationType })
export class DocumentTypeRelation extends BaseApiModel<DocumentTypeRelationPlainModel> {

  // value for document_type_field
  @Attribute()
  confidence_lower_threshold: number;

  // value for document_type_field
  @Attribute()
  confidence_upper_threshold: number;

  @Attribute()
  discarded_at: string;

  @Attribute()
  document_type_field_id: number;

  @Attribute()
  document_type_fieldset_id: number;

  @Attribute()
  document_type_id: number;

  @Attribute()
  document_type_section_id: number;

  @Attribute()
  extraction_rules: ExtractionRule[];

  // value for document_type_field
  @Attribute()
  identifier: string;

  // value for document_type_field
  @Attribute()
  not_for_training: boolean;

  // value for document_type_field
  @Attribute()
  not_for_validation: boolean;

  @Attribute()
  optional: boolean;

  @Attribute()
  position: number;

  @Attribute()
  positioning: Positioning;

  // value for document_type_field
  @Attribute()
  recognition_confidence_threshold: number;

  @Attribute()
  tenant_id: number;

  // value for document_type_field
  @Attribute()
  title: string;

  @Attribute()
  use_standard: boolean;

  // value for document_type_field
  @Attribute()
  validation_help: string;

  @Attribute()
  version: string;

  // Includes / Relations

  @BelongsTo({ class: DocumentTypeRelationType, sidepostable: false })
  parent: (DocumentTypeRelation | Partial<DocumentTypeRelation>);

  @BelongsTo({ class: DocumentTypeFieldType, sidepostable: true })
  document_type_field: DocumentTypeField;

  @BelongsTo({ class: DocumentTypeFieldsetType })
  document_type_fieldset: DocumentTypeFieldset;

  get plainModel(): DocumentTypeRelationPlainModel {
    const model = this.toHash() as DocumentTypeRelationPlainModel;
    model.recognition_threshold = this.recognition_confidence_threshold || this.recognition_confidence_threshold === 0 ? this.recognition_confidence_threshold * 100 : 95;
    model.confidence_threshold = [
      this.confidence_lower_threshold || this.confidence_lower_threshold === 0 ? this.confidence_lower_threshold * 100 : 30,
      this.confidence_upper_threshold || this.confidence_upper_threshold === 0 ? this.confidence_upper_threshold * 100 : 95
    ];

    return model;
  }

  set plainModel(model) {
    this.customUpdates = {
      confidence_lower_threshold: setConfidenceLowerThreshold,
      confidence_upper_threshold: setConfidenceUpperThreshold,
      recognition_confidence_threshold: setRecognitionConfidenceThreshold,
    };

    this.setPlainModel(model);
  }
}

function setConfidenceLowerThreshold(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel['confidence_threshold'] && Array.isArray(updatedPlainModel['confidence_threshold'])) {
    existingModel[key] = updatedPlainModel['confidence_threshold'][0] / 100;
  } else {
    existingModel[key] = updatedPlainModel[key];
  }
}

function setConfidenceUpperThreshold(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel['confidence_threshold'] && Array.isArray(updatedPlainModel['confidence_threshold'])) {
    existingModel[key] = updatedPlainModel['confidence_threshold'][1] / 100;
  } else {
    existingModel[key] = updatedPlainModel[key];
  }
}

function setRecognitionConfidenceThreshold(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel['recognition_threshold']) {
    existingModel[key] = updatedPlainModel['recognition_threshold'] / 100;
  } else {
    existingModel[key] = updatedPlainModel[key];
  }
}

export function sortRelations(relations: DocumentTypeRelation[]): DocumentTypeRelation[] {
  relations = relations.filter(x => x.discarded_at === null);

  const sorted = sortBy(relations, x => x.positioning.row);
  const grouped = groupBy(sorted, x => x.positioning.row);

  return Object
    .values(grouped)
    .map(row => sortBy(row, relation => relation.positioning.position))
    .reduce((all, batch) => all.concat(batch), []);
}
